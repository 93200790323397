// import '@babel/polyfill'
import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import VueNotify from 'vue-notifyjs'

// import VeeValidate from 'vee-validate'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm';
import { extend } from "vee-validate/dist/vee-validate.full.esm";

// import { ValidationProvider } from 'vee-validate';
// import { ValidationObserver } from 'vee-validate';
// import { extend } from "vee-validate";
// import { required, email, length, between, min, max } from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('length', {
  ...length,
  message: "The {_field_} field must have {length} digits"
});


extend('url', {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
    }

    return false;
  },
  message: 'The {_field_} field must be a valid website address',
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import VueScrollTo from 'vue-scrollto'

import App from './App.vue'

//import { router } from './router';
import { store } from '@/store';
import axios from 'axios';
import NProgress from 'nprogress';

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
//import initProgress from './progressbar';

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

// router setup
import routes from './routes/routes'

// charting
import Highcharts from 'highcharts'
import Gantt from "highcharts/modules/gantt";
import HighchartsVue from 'highcharts-vue'
import highchartsMore from 'highcharts/highcharts-more'
import highchartsGauges from 'highcharts/modules/solid-gauge'
import HighchartsCustomEvents from "highcharts-custom-events";
import highchartsPatterns from 'highcharts/modules/pattern-fill';

Gantt(Highcharts);
highchartsMore(Highcharts);
highchartsGauges(Highcharts);
highchartsPatterns(Highcharts);
HighchartsCustomEvents(Highcharts);

Highcharts.setOptions({
    // options here
    lang: {
        thousandsSep: ","
    }
  })

Vue.use(HighchartsVue)

// tree
import VJstree from './components/tree' // 'vue-jstree'
Vue.use(VJstree);

// lodash
import _ from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: _ });

// intercom
import VueIntercom from '@mathieustan/vue-intercom';
import intercom from '@/mixins/intercom';
if (process.env.VUE_APP_USE_INTERCOM == 1) {
  Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID, hide_default_launcher: true });  // hide launcher so commandbar is primary
  Vue.mixin(intercom); 
}

// commandBar
import { init } from 'commandbar';
import commandBarMixin from '@/mixins/commandbar';

if (process.env.VUE_APP_USE_COMMANDBAR == 1) {
  init(process.env.VUE_APP_COMMANDBAR_ID);        
  Vue.mixin(commandBarMixin); 
}


// filters
import { formatDate, formatLongDate, formatDateTime, formatDateTimeTz, yesNo, formatDollars, formatDollars2Decimals, formatNumber, formatNumber1Decimal, formatNumber2Decimals, formatPercent, formatPhone, formatUrl } from '@/helpers';
Vue.filter("formatDate", formatDate);
Vue.filter("formatLongDate", formatLongDate);
Vue.filter("formatDateTime", formatDateTime);
Vue.filter("formatDateTimeTz", formatDateTimeTz);
Vue.filter("yesNo", yesNo);
Vue.filter("formatDollars", formatDollars);
Vue.filter("formatDollars2Decimals", formatDollars2Decimals);
Vue.filter("formatNumber", formatNumber);
Vue.filter("formatNumber1Decimal", formatNumber1Decimal);
Vue.filter("formatNumber2Decimals", formatNumber2Decimals);
Vue.filter("formatPercent", formatPercent);
Vue.filter("formatPhone", formatPhone);
Vue.filter("formatUrl", formatUrl);

// library imports

import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'
import 'vue-notifyjs/themes/default.css'

// tailwind
import './assets/tailwind.css'

// kendo
import './plugins/kendo-ui-vue'
// notifications built on kendo
import PopupNotifications from "src/components/PopupNotifications";
Vue.component('PopupNotifications', PopupNotifications)
// entity details tooltip built on kendo
import EntityTooltip from "src/components/EntityTooltip";
Vue.component('EntityTooltip', EntityTooltip)
import SupplierTooltip from "src/components/SupplierTooltip";
Vue.component('SupplierTooltip', SupplierTooltip)
import QuestionTooltip from "src/components/QuestionTooltip";
Vue.component('QuestionTooltip', QuestionTooltip)

// ag-grid
import './plugins/ag-grid'

// tiny mce
import './plugins/tiny-mce'


// site css
import './assets/site.css'

// other
import sidebarLinks from './sidebarLinks'

import './registerServiceWorker'


// plugin setup
Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
// Vue.use(VeeValidate, {fieldsBagName: 'formFields'})
locale.use(lang)
Vue.use(VueScrollTo)

// page title
if (process.env.NODE_ENV != 'production') {
  document.title = 'GetInSync DEV'
}


// axios setup
import { AUTHENTICATION_LOGOUT } from "@/store/actions.type";

// base url
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
// pre-existing user token
const user = JSON.parse(localStorage.getItem('user'));
if (user) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
}

// before a request is made start the nprogress
axios.interceptors.request.use(config => {
    NProgress.start();
    return config;
  })

// handle any unauthorized responses
axios.interceptors.response.use(function (response) {
  NProgress.done();
  return response;
}, function (error) {
  NProgress.done();
  return new Promise(function (resolve, reject) {
    // debugger;
    if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
      // if you ever get an unauthorized, logout the user
      store.dispatch(AUTHENTICATION_LOGOUT)
        .then(() => {
          router.push('/login');
        })
    }
    if (error.response.status === 403 && error.config && !error.config.__isRetryRequest) {
      // if you ever get a forbid, logout the user
      router.push('/account_check');
    }      
    throw error;
  });
});


// // configure router
// const router = new VueRouter({
//   mode: 'history',
//   routes, // short for routes: routes
//   linkActiveClass: 'active',
//   scrollBehavior: (to) => {
//     if (to.hash) {
//       return {selector: to.hash}
//     } else {
//       return { x: 0, y: 0 }
//     }
//   }
// })

// // initProgress(router);

// router.beforeEach((to, from, next) => {
//     // NProgress.start();
//     // redirect to login page if not logged in and trying to access a restricted page
//     //const publicPages = ['/login', '/register', '/'];
//     //const authRequired = !publicPages.includes(to.path);
//     //debugger
//     const authRequired = !to.matched.some(route => route.meta.allowAnonymous);
//     const sysAdminRequired = to.matched.some(route => route.meta.sysAdminRequired);
//     const loggedIn = store.getters['authentication/isAuthenticated'];
//     const isSystemAdministrator = store.getters['authentication/isSystemAdministrator'];
//     const isHomePage = (to.path === '/');
//     const user = store.getters['authentication/loggedInUser'];
  
//     // auth required but not logged in
//     if ((authRequired || sysAdminRequired) && !loggedIn) {
//       return next('/login');
//     }

//     // this is a temporary check for our conversion from ids to guids - if the user is logged in with an id, they need to login again to get a guid
//     if (loggedIn && user.userId.length != 36 && to.path != '/login') {
//       return next('/login');
//     }
  
//     if (loggedIn && isHomePage) {
//       return next('/overview');
//     }
  
//     // maybe temporary, redirect straight to login if they hit the home page
//     if (isHomePage) {
//       return next('/login');
//     }

//     // is system admin required
//     if (sysAdminRequired && !isSystemAdministrator) {
//       return next('/overview');
//     }

//     // email address not verified
//     if (loggedIn && !user.emailAddressVerified && to.path != '/overview' && to.path != '/admin/verifyemail' && to.path != '/emailverification' && to.path != '/verifyemail' && to.path != '/logout') {
//       NProgress.done();
//       return next('/overview');
//     }
    
    
//     // no role specified, just authenticated
//     if (authRequired 
//           && !to.matched.some(route => route.meta.role) 
//           && !to.matched.some(route => route.meta.roles)
//           && !to.matched.some(route => route.meta.right) 
//           && !to.matched.some(route => route.meta.rights)) {
//       return next();
//     }
  
//     // single role specified, doesn't match
//     if (authRequired 
//           && to.matched.some(route => route.meta.role)
//           && !to.matched.some(route => store.getters['authentication/isInRole'](route.meta.role))) {
//       return next('/overview');
//     }
  
//     // multiple roles specified, doesn't match
//     if (authRequired 
//       && to.matched.some(route => route.meta.roles)
//       && !to.matched.some(route => route.meta.roles.some(role => store.getters['authentication/isInRole'](role)))) {
//       return next('/overview');
//     }
  
//     // single right specified, doesn't match
//     if (authRequired 
//       && to.matched.some(route => route.meta.right)
//       && !to.matched.some(route => store.getters['authentication/hasRightOrPortfolioRight'](route.meta.right))) {
//       return next('/overview');
//     }
  
//     // multiple rights specified, doesn't match
//     if (authRequired && to.matched.some(route => route.meta.rights) ) {
//         // debugger;
//         var matchedRoutes = to.matched.filter(route => route.meta.rights);
//         // check that every matching route (parent/children) with rights has access
//         if (!matchedRoutes.every(route => route.meta.rights.some(right => store.getters['authentication/hasRightOrPortfolioRight'](right)))) {
//             return next('/overview');
//         }
//     }

//     next();
//   });

import { router } from "@/helpers";

//   router.afterEach((to, from) => {
//     NProgress.done();
//   });

// add router function to commandbar
// if (process.env.VUE_APP_USE_COMMANDBAR == 1)
//   window.CommandBar.addRouter(router.push);


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})
